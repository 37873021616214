const faqData = [
    {
        title: "How Extensive is the Shipping Time?",
        content: "The duration of shipping is contingent upon the specific item, with estimated transfer times detailed in the product description. Customers bear the responsibility for all custom fees imposed by their nation."
    },
    {
        title: "How Should I Cleanse This?",
        content: "To uphold the integrity of your attire, we strongly advise folding it inside out, laundering with frigid water, and subjecting it to low heat tumble drying."
    },
    {
        title: "Realm Directives; Every Transaction is Irrevocable!",
        content: "Once the item embarks on its journey, we are unable to furnish a refund. Scrutinize product descriptions diligently before committing to a purchase. Our influence over packages ceases upon dispatch. In the event of an order's disapperance or pilferage, the onus lies with the customer to seek aid from their shipping courier. For anomalies like a defective or absent item contact thevisiongarments00@gmail.com for further assistance. By procuring from and navigating our website, you align yourself with these terms and stipulations."
    },
];
export default faqData;