import React from 'react';

const TableOfContents = ({ headings }) => {
  return (
    <nav className="toc">
      <ul>
        {headings.map((heading, index) => (
          <li key={index} style={{ marginLeft: `${heading.level - 3}rem`, paddingBottom: '.5rem' }}>
            <a
              href={`#${heading.id}`}
              onClick={(e) => {
                e.preventDefault();
                document.querySelector(`#${heading.id}`).scrollIntoView({ behavior: 'smooth' });
              }}
            >
              {heading.text}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TableOfContents;
