import { createSlice } from "@reduxjs/toolkit";

import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../firebase.config";
import { getUserTier } from "../../components/Helpers/CouponHelper";

const initialState = {
    user: null,
    isAuthenticated: false,
    loading: false,
    error: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            state.isAuthenticated = true;
        },
        clearUser: (state) => {
            state.user = null;
            state.isAuthenticated = false;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        claimCoupon: (state) => {
            if (state.user) {
                state.user.claimedCoupons = [...(state.user.claimedCoupons || []), state.user.couponCode]; // Add to claimedCoupons
                state.user.couponCode = null; // Remove coupon after claiming
            }
        },
    },
});

export const { setUser, clearUser, setLoading, setError, claimCoupon } = userSlice.actions;
export default userSlice.reducer;

export const fetchUserData = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const user = auth.currentUser;
        if (user) {
            const userDocRef = doc(db, 'users', user.uid);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                const { firstName, lastName, email, phoneNumber, address, points, claimedCoupons = [] } = userData;

                const userTier = getUserTier(points);
                const hasClaimedTier = userTier && claimedCoupons.includes(userTier.coupon);

                dispatch(setUser({
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    address,
                    points,
                    claimedCoupons,
                    tier: userTier?.tier || null,
                    couponCode: hasClaimedTier ? null : userTier?.coupon 
                }));
            }
        }
    } catch (error) {
        dispatch(setError(error.message));
    } finally {
        dispatch(setLoading(false));
    }
};


export const logoutUser = () => (dispatch) => {
    auth.signOut().then(() => {
        dispatch(clearUser());
        localStorage.removeItem('user');
    }).catch((error) => {
        dispatch(setError(error.message));
    });
};
