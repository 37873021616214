import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Helmet from '../components/Helmet/Helmet';
import { Row, Col } from 'reactstrap';
import { auth, db } from '../firebase.config';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

import '../styles/profile.css';
import AlcolyteImage from '../assets/images/tier1-image.png'
import DiscipleImage from '../assets/images/tier2-image.png'
import SovereignImage from '../assets/images/tier3-image.png'
import OculistImage from '../assets/images/tier4-image.png'
import img100 from '../assets/images/100ptsimg.png';
import img250 from '../assets/images/250ptsimg.png';
import img500 from '../assets/images/500ptsimg.png';
import img1000 from '../assets/images/1000ptsimg.png';

const Profile = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [userTier, setUserTier] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            const storedUser = await localStorage.getItem('user');
            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser);
                determineTier(parsedUser.points);
                setLoading(false);
            } else {
                const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
                    if (authUser) {
                        const userDocRef = doc(db, "users", authUser.uid);
                        const userDocSnap = await getDoc(userDocRef);
                        if (userDocSnap.exists()) {
                            const { firstName, lastName, email, phoneNumber, points } = userDocSnap.data();
                            const userData = { firstName, lastName, email, phoneNumber, points };
                            setUser(userData);
                            localStorage.setItem('user', JSON.stringify(userData));
                            determineTier(points);
                        }
                    } else {
                        navigate('/login');
                    }
                    setLoading(false);
                });

                return () => unsubscribe();
            }
        }
        fetchProfile();
    }, [navigate]);

    const determineTier = (points) => {
        switch (true) {
            case (points < 100):
                setUserTier('Alcolyte');
                break;
            case (points < 500):
                setUserTier('Disciple');
                break;
            case (points < 1000):
                setUserTier('Sovereign');
                break;
            default:
                setUserTier('Oculist');
                break;
        }
    };

    const getProfileImage = (tier) => {
        switch (userTier) {
            case "Alcolyte":
                return AlcolyteImage;
            case "Disciple":
                return DiscipleImage;
            case "Sovereign":
                return SovereignImage;
            case "Oculist":
                return OculistImage;
            default:
                return AlcolyteImage;
        }
    };

    const profileImage = getProfileImage(userTier);


    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return null;
    }
    
    return <Helmet title='Profile'>
        <section>
            <Row>
                <div className='profile-header'>
                    <div className='profile-header-width'>
                        <h2>Welcome back to the Brotherhood</h2>
                        <span>Points: {user.points}</span>
                    </div>
                </div>
            </Row>
        </section>

        <section className='profile_section'>
            <Row className='profile-bio'>
                <Col md={6}>
                    <div className='level-info'>
                        <img src={profileImage} alt='' />
                        <div className='level-details'>
                            <h6>{user.firstName} {user.lastName}</h6>
                            <p>{userTier}</p>
                        </div>
                    </div>
                </Col>

                <Col md={6}>
                    <h3>Insightful Discourse</h3>
                </Col>
            </Row>

            <Row className='profile-bio p-3'>
                <Col lg={3} md={4}>
                    <p>Email</p>
                    <p>{user.email}</p>
                </Col>

                <Col lg={3} md={4}>
                    <p>Phone</p>
                    <p>{user.phoneNumber}</p>
                </Col>
            </Row>

            <Row className='profile-bio p-3'>
                <Col lg={3} md={4}>
                    <p>Address</p>
                    <p>{user.address || "N/A"}</p>
                </Col>
            </Row>
        </section>

         <section className='coupon-section'>
            <Row className='coupon-row'>
                <Col md={6}>
                    <div className='coupon-card'>
                        <img src={img100} style={{maxWidth: 240}}alt='' />
                        <p>100 Points</p>
                        <p>10$ off discount coupon</p>
                    </div>
                </Col>

                <Col md={6}>
                    <div className='coupon-card'>
                        <img src={img250} style={{maxWidth: 240}}alt='' />
                        <p>250 Points</p>
                        <p>30$ off Discount coupon</p>
                    </div>
                </Col>
            </Row>

            <Row className='coupon-row'>
                <Col md={6}>
                    <div className='coupon-card'>
                        <img src={img500} style={{maxWidth: 240}}alt='' />
                        <p>500 Points</p>
                        <p>65$ off discount coupon</p>
                    </div>
                </Col>

                <Col md={6}>
                    <div className='coupon-card'>
                        <img src={img1000} style={{maxWidth: 240}}alt='' />
                        <p>1000 Points</p>
                        <p>150$ off discount coupon</p>
                    </div>
                </Col>
            </Row>

        </section>
  </Helmet>
}

export default Profile