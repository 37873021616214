import { useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase.config';
import './App.css';
import Layout from './components/Layout/Layout';

function App() {
    useEffect(() => {
        const hasSeeded = sessionStorage.getItem('superAdminSeeded');

        if (!hasSeeded) {
            const seedSuperAdmin = httpsCallable(functions, 'seedSuperAdmin');

            seedSuperAdmin()
                .then((result) => {
                    sessionStorage.setItem('superAdminSeeded', 'true');
                })
                .catch((error) => {
                });
        }
    }, []);
    return <Layout />;
}

export default App;
