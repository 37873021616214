import React, {useState} from 'react';

import { motion } from 'framer-motion';

import '../../styles/cart-product.css';
import { useDispatch } from 'react-redux';
import { cartActions } from '../../redux/slices/cartSlice';


const CartProduct = ({cartItems}) => {        
    return (
        <>
            {cartItems.map((item, index) => (
                <Tr item={item} key={index}/> 
            ))}
        </>
    )
};

const Tr = ({item, index}) => {
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(item.quantity);

    const handleChange = (event) => {
        let newQuantity = event.target.valueAsNumber || 0;

        if (newQuantity < 1) {
            newQuantity = 1;
        }

        setQuantity(newQuantity);

        dispatch(cartActions.updateItemQuantity({ 
            id: item.id, 
            size: item.size,  // ✅ Include size to identify the correct item
            quantity: newQuantity 
        }));
    };

    const deleteProduct = () => {
        dispatch(cartActions.deleteItem({
            id: item.id,
            size: item.size
        }));
    };

    return (
        <div className='cart_product-wrapper'>
            <div className='cart-desc'>
                <img src={item.imgUrl[0]} alt={item.productName}/>
                <div className='cart-details'>
                    <h2>{item.productName}</h2>
                    <p>$ {item.price} USD</p>
                    {item.size && (<p> SIZE: {item.size}</p>)}
                    <motion.span className='' whileTap={{scale: 1.2}} onClick={deleteProduct} >Remove</motion.span>
                </div>
            </div>

            <div className='cart-input'>
                <input 
                    type='number' 
                    min={1}
                    value={quantity} 
                    onChange={(event) => handleChange(event, index)}
                />
            </div>
        </div>
    )
}

export default CartProduct