import { updateDoc, doc, arrayUnion } from "firebase/firestore";
import { auth, db } from "../../firebase.config";
import { setUser, setError } from "../../redux/slices/userSlice";

export const getUserTier = (points, claimedCoupons = []) => {
    const tiers = [
        { points: 2500, tier: "OCULIST", coupon: "OCULIST30", discount: 30 },
        { points: 1000, tier: "SOVEREIGN", coupon: "SOVEREIGN20", discount: 20 },
        { points: 500, tier: "DISCIPLE", coupon: "DISCIPLE15", discount: 15 },
        { points: 1, tier: "ACOLYTE", coupon: "ACOLYTE10", discount: 10 }
    ];

    return tiers.find(tier => points >= tier.points && !claimedCoupons.includes(tier.coupon)) || null;
};

export const claimCoupon = () => async (dispatch, getState) => {
    const { user } = getState().user;
    if (!user || !user.couponCode) return;

    try {
        const userDocRef = doc(db, "users", auth.currentUser.uid);

        // Add the coupon to Firestore
        await updateDoc(userDocRef, { 
            claimedCoupons: arrayUnion(user.couponCode) 
        });

        // Update Redux state
        dispatch(setUser({ 
            ...user, 
            claimedCoupons: [...(user.claimedCoupons || []), user.couponCode], 
            couponCode: null
        }));
    } catch (error) {
        dispatch(setError(error.message));
    }
};



// 20 40 100