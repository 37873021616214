import React, { useEffect } from 'react';

const Helmet = ({ title, description, children }) => {
    useEffect(() => {
        document.title = 'lordvisiongarments - ' + title;

        if (description) {
            let metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute('content', description);
            } else {
                metaDescription = document.createElement('meta');
                metaDescription.name = 'description';
                metaDescription.content = description;
                document.head.appendChild(metaDescription);
            }
        }

        // Check if the script is already added
        if (!document.querySelector(`script[src="https://connect.facebook.net/en_US/fbevents.js"]`)) {
            (function(f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function() {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

            window.fbq('init', '2972530686247006');
        }

        // Always track page views dynamically
        window.fbq('track', 'PageView');

    }, [title, description]);

    return <div className='w-100'>{children}</div>;
};

export default Helmet;
