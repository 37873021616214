import React from 'react';
import { motion } from 'framer-motion';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify'; 

import { useDispatch } from 'react-redux';
import { cartActions } from '../../redux/slices/cartSlice';

import '../../styles/product-card.css';
import "react-toastify/dist/ReactToastify.css";


const ProductCard = ({item}) => {
    const dispatch = useDispatch();

    const CustomToast = ({ product }) => (
        <div className="cart_custom-toast">
            <span className="cart_toast-icon"><i class="ri-checkbox-circle-line"></i></span>
            <div className="cart_toast-message">
                <p className='cart_toast-text'>Product added to your cart.</p>
            </div>
            <img 
                src={product.imgUrl[0]}
                alt={product.productName} 
                className="cart_toast-image"
            />
        </div>
    );

    const addToCart = () => {
        const isOutOfStock = !item.quantity || item.sizes?.every(size => !size.quantity);

        if (isOutOfStock) {
            toast.error("Out of Stock", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                closeButton: false,
                className: "out-of-stock-toast",
            });
            return;
        }

        const product = {
            id: item.id,
            productName: item.productName,
            price: item.price,
            imgUrl: item.imgUrl, 
        };

        dispatch(cartActions.addItem(product));

        toast(<CustomToast product={product} />, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            closeButton: false,
            className: "add-to-cart-toast",
        });
    };


    return (
        <Col className='col-6' lg={3} md={6} sm={6}>
            <div className='product-item'>
                <Link to={`/productDetails/${item.id}`}>
                    <div className='product-image-container'>
                        <motion.img whileHover={{scale: 0.9}} src={item.imgUrl[0]} className='product-image' alt={item.productName} />
                    </div>
                 </Link>
                <div className='product-info'>
                    <h3 className='product-name'>
                        <Link to={`/productDetails/${item.id}`}>
                            {item.productName.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())}
                        </Link>
                    </h3>
                    {(!item.quantity || item.sizes?.every(size => !size.quantity)) && (
                        <span className="out-of-stock-btn"> Sold Out</span>
                    )}
                </div>
                <div className='product_card-bottom d-flex align-items-center justify-content-between'>
                    <span className='price'>${item.price}</span>
                    {item.sizes && item.sizes.length === 0 && (
                        <motion.span role='button' whileTap={{scale: 1.2}} onClick={addToCart}>
                            <i class="ri-add-line"></i>
                        </motion.span>
                    )}
                </div>
            </div>
        </Col>
    )
}

export default ProductCard