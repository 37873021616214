import React, {useState, useEffect} from 'react';

import { 
    Container,
    Row, 
    Col, 
    Dropdown, 
    DropdownToggle, 
    DropdownMenu, 
    DropdownItem 
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import Helmet from '../components/Helmet/Helmet';
import ProductAccordion from '../components/Accordion/ProductAccordion';

import { useDispatch } from 'react-redux';
import { cartActions } from '../redux/slices/cartSlice';

import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase.config';

import { toast } from 'react-toastify'; 

import '../styles/product-details.css';
import Carousel from '../components/Carousel/Carousel';
 

const ProductDetails = () => {

    
    const {id} = useParams();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedSize, setSelectedSize] = useState();
    const [product, setProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const docRef = doc(db, "products", id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setProduct({ id: docSnap.id, ...docSnap.data() });
                } else {
                    return
                }
            } catch (error) {
                // console.error("Error fetching product: ", error);
            } finally {
                setLoading(false);
            }
        };
        fetchProduct();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    const { imgUrl, vidUrl, category, productName, price, details, sizes }  = product;


    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const accordionData = [
        { title: 'Details', content: product.details },
        { title: 'Shipping', content: product.shipping },
        { title: 'Returns', content: product.returns },
    ]; 

    const nftAccordionData = [
        { title: 'Details', content: product.details },
    ]; 

    const handleSelectedSize = (item) => {
        setSelectedSize(item)
    }

    const handleQuantity = (event) => {
        const newQuantity = event.target.valueAsNumber || 1;
        setQuantity(newQuantity)
    }

    const isOutOfStock = !product.quantity || (sizes && sizes.every(size => !size.quantity));


    const CustomToast = ({ product }) => (
        <div className="cart_custom-toast">
            <span className="cart_toast-icon"><i class="ri-checkbox-circle-line"></i></span>
            <div className="cart_toast-message">
                <p className='cart_toast-text'>Product added to your cart.</p>
            </div>
            <img 
                src={product.imgUrl[0]}
                alt={product.productName} 
                className="cart_toast-image"
            />
        </div>
    );

    const addToCart = () => {
        const isOutOfStock = !product.quantity || product.sizes?.every(size => !size.quantity);

        if (sizes && sizes.length > 0 && !selectedSize) {
            toast.error('Please select a size');
            return;
        }
        if (isOutOfStock) {
            toast.error("Out of Stock", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                closeButton: false,
                className: "out-of-stock-toast",
            });
            return;
        }

        // const product = {
        //     id: pro.id,
        //     productName: item.productName,
        //     price: item.price,
        //     imgUrl: item.imgUrl, 
        // };

        dispatch(cartActions.addItem(product));

        toast(<CustomToast product={product} />, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            closeButton: false,
            className: "add-to-cart-toast",
        });
    };

    // const addToCart = () => {

    //     if (sizes && sizes.length > 0 && !selectedSize) {
    //         toast.error('Please select a size');
    //         return;
    //     }
    //     dispatch(cartActions.addItem({
    //         id: id,
    //         productName: productName,
    //         price: price,
    //         imgUrl: imgUrl,
    //         size: selectedSize,
    //         quantity: quantity
    //     }));

    //     toast(<CustomToast product={product} />, {
    //         position: "top-right",
    //         autoClose: false,
    //         hideProgressBar: true,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         closeButton: false,
    //         className: "add-to-cart-toast",
    //     });
    // }

    return <Helmet title={'Product details'}>
        <section className='product-section'>
            <Container>
                {category==='Physical' &&
                    <Row>
                        <Col lg="6" >
                            <Carousel imageUrls={imgUrl}/>
                        </Col>

                        <Col lg="6">
                            <div className='product-details'>
                                <h1 className='product-name mb-2'>{productName}</h1>
                                {category === 'NFT'}
                                <span className='product-price mb-2'>$ {price} USD</span>
                                <p className='description mb-2'>{details}</p>
                                <div className='d-flex flex-row align-items-end pb-2'>
                                    <div className='quantity'>
                                        <span>Quantity</span>
                                        <input 
                                            type='number'
                                            value={quantity}
                                            onChange={handleQuantity}
                                            min={1} 
                                            className='quantity-input input-height' />
                                    </div>

                                    {sizes && sizes.length > 0 && (
                                        <div className='size-dropdown'>
                                            <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                                                <DropdownToggle className='dropdown-toggle input-height' caret>
                                                    {selectedSize ? selectedSize : 'Select size'}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {sizes.map((item, index) => (
                                                        <DropdownItem key={index} onClick={() => handleSelectedSize(item)}>{item}</DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    )}
                                </div>

                                <button 
                                    className='product-btn' 
                                    onClick={addToCart}
                                    disabled={isOutOfStock}
                                >
                                    {isOutOfStock ? "Out of Stock" : "Add to Cart"}
                                </button>

                                {!isOutOfStock && (
                                    <button className='product-btn'>Buy now</button>
                                )}
                                <ProductAccordion accordionData={accordionData}/>
                            </div>
                        </Col>
                    </Row>
                }

                {category==='NFT' &&
                    <Row>
                        <Col lg={6} md={6} sm={12} className='video-col' >
                            <video 
                                src={vidUrl}
                                autoPlay
                                playsInline
                                loop
                                muted
                                className="responsive-video"
                            />
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                            <div className='product-details'>
                                <h1 className='product-name mb-2'>{productName}</h1>
                                <span className='product-price mb-2'>{price} ETH</span>
                                <p className='description mb-2'>{details}</p>
                                <button className='product-btn'>Buy with ETH</button>
                                <ProductAccordion accordionData={nftAccordionData}/>
                            </div>
                        </Col>
                    </Row>
                }
            </Container>
        </section>
    </Helmet>
}

export default ProductDetails 