import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase.config';

const ManageOrders = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
            const querySnapshot = await getDocs(collection(db, 'purchases'));
            const ordersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setOrders(ordersData);
        };
        fetchOrders();
    }, []);

    return (
        <div>
            <h2>Manage Orders</h2>
            <table>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>User ID</th>
                        <th>User Email</th>
                        <th>Full Name</th>
                        <th>Shipping Address</th>
                        <th>Points Used</th>
                        <th>Items Worth (USD)</th>
                        <th>Shipping Fee (USD)</th>
                        <th>Amount Paid (USD)</th>
                        <th>Shipping Status</th>
                        <th>Ordered At</th>
                        <th>Updated At</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => (
                        <tr key={order.id}>
                            <td>{order.id}</td>
                            <td>{order.userId}</td>
                            <td>{order.email}</td>
                            <td>{order.shippingAddress.fullName}</td>
                            <td>
                                {order.shippingAddress
                                    ? `${order.shippingAddress.street}, ${order.shippingAddress.city}, ${order.shippingAddress.state}, ${order.shippingAddress.zip}, ${order.shippingAddress.country}`
                                    : 'N/A'}
                            </td>
                            <td>{order.pointsUsed}</td>
                            <td>{order.itemsPrice}</td>
                            <td>{order.shippingFee}</td>
                            <td>{order.amountPaid}</td>
                            <td>{order.shippingStatus}</td>
                            <td>{order.createdAt ? new Date(order.createdAt.seconds * 1000).toLocaleString() : 'N/A'}</td>
                            <td>{order.updatedAt ? new Date(order.updatedAt.seconds * 1000).toLocaleString() : 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ManageOrders;
