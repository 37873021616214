
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    cartItems: [],
    totalAmount: 0,
    totalQuantity: 0
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItem: (state, action) => {
            const newItem = action.payload;
            const existingItem = state.cartItems.find(
                item => item.id === newItem.id &&
                item.size === newItem.size);

            if (!existingItem) {
                state.cartItems.push({
                    id: newItem.id,
                    productName: newItem.productName,
                    imgUrl: newItem.imgUrl,
                    price: newItem.price,
                    size: newItem.size,
                    quantity: newItem.quantity || 1,
                    totalPrice: newItem.price * (newItem.quantity || 1)
                });
            } else {
                existingItem.quantity += newItem.quantity || 1;
                existingItem.totalPrice = existingItem.quantity * existingItem.price;
            }

            state.totalQuantity = state.cartItems.reduce((total, item) => total + item.quantity, 0);
            state.totalAmount = state.cartItems.reduce((total, item) => total + item.totalPrice, 0);
        },

        deleteItem: (state, action) => {
            const {id, size} = action.payload
            const existingItemIndex = state.cartItems.findIndex((item) => (item.id === id && item.size === size));

            if (existingItemIndex !== -1) {
                const existingItem = state.cartItems[existingItemIndex];
                state.totalQuantity -= existingItem.quantity;
                state.totalAmount -= existingItem.totalPrice;

                state.cartItems.splice(existingItemIndex, 1);
            }
        },

        updateItemQuantity: (state, action) => {
            const { id, size, quantity } = action.payload;
            
            // Find item by id and size
            const existingItem = state.cartItems.find(item => item.id === id && item.size === size);

            if (existingItem) {
                existingItem.quantity = quantity;
                existingItem.totalPrice = existingItem.quantity * existingItem.price;
            }

            // ✅ Always recalculate total quantity
            state.totalQuantity = state.cartItems.reduce((total, item) => total + item.quantity, 0);

            // ✅ Always recalculate total amount
            state.totalAmount = state.cartItems.reduce((total, item) => total + item.totalPrice, 0);
        }
    }
});

export const cartActions = cartSlice.actions

export default cartSlice.reducer