import React, { useState, useEffect } from 'react';
import { db, storage } from '../../../firebase.config'; // Assuming Firebase config is in this file
import { collection, addDoc, updateDoc, doc, deleteDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; 
import '../../../styles/manageProducts.css'; // Add your CSS file for styles

const ManageProducts = () => {
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [productData, setProductData] = useState({
        category: '',
        details: '',
        imgUrl: [],
        price: '',
        productName: '',
        productType: '',
        returns: '',
        shipping: '',
        sizes: {},
        quantity: ''
    });
    const [editProductId, setEditProductId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [useSizes, setUseSizes] = useState(false);

    // Fetch all products
    const fetchProducts = async () => {
        const productsRef = collection(db, "products");
        const querySnapshot = await getDocs(productsRef);
        setProducts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setLoading(false);
    };

    // Add product
    const addProduct = async () => {
        try {
            setUploading(true);

            // Upload images if any are selected
            const uploadedImageUrls = [];
            for (const file of selectedImages) {
                const storageRef = ref(storage, `products/${productData.category}/${productData.productName}/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        "state_changed",
                        null,
                        (error) => reject(error),
                        async () => {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            uploadedImageUrls.push(downloadURL);
                            resolve();
                        }
                    );
                });
            }

            // Store product with uploaded images
            const newProductData = {
                ...productData,
                imgUrl: uploadedImageUrls, // Assign uploaded image URLs
            };

            const productsRef = collection(db, "products");
            await addDoc(productsRef, newProductData);

            setUploading(false);
            setShowModal(false);
            setSelectedImages([]); // Clear selected images after upload
            fetchProducts();
            
        } catch (error) {
            console.error("Error adding product:", error);
            setUploading(false);
        }
    };

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        setSelectedImages(files);
    };

    const calculateQuantity = () => {
        if (!useSizes) return productData.quantity; // Use manual quantity if sizes are not selected
        return Object.values(productData.sizes).reduce((total, qty) => total + (qty || 0), 0);
    };

    const handleSizeToggle = (e) => {
        setUseSizes(e.target.checked);
        if (!e.target.checked) {
            setProductData(prev => ({ ...prev, sizes: {}, quantity: '' })); // Reset sizes if unchecked
        }
    };

    const handleSizeChange = (event) => {
        const { value, checked } = event.target;
        setProductData((prevData) => {
            const newSizes = { ...prevData.sizes };

            if (checked) {
                newSizes[value] = newSizes[value] || 1; // Default quantity is 1 when checked
            } else {
                delete newSizes[value]; // Remove size if unchecked
            }

            // Calculate total quantity from sizes
            const totalQuantity = Object.values(newSizes).reduce((sum, qty) => sum + qty, 0);

            return { ...prevData, sizes: newSizes, quantity: totalQuantity };
        });
    };

    // Handler for independent quantity field
    const handleQuantityChange = (size, event) => {
        const newQuantity = Math.max(1, parseInt(event.target.value, 10) || 1); // Ensure valid number

        setProductData(prev => {
            if (!prev.sizes) return prev; // Prevent crashing if sizes is undefined

            const updatedSizes = { ...prev.sizes, [size]: newQuantity };

            // Recalculate total quantity from sizes
            const totalQuantity = Object.values(updatedSizes).reduce((sum, qty) => sum + qty, 0);

            return { ...prev, sizes: updatedSizes, quantity: totalQuantity };
        });
    };



    // Edit product
    const editProduct = async () => {
        try {
            const productRef = doc(db, "products", editProductId);
            await updateDoc(productRef, productData);
            setShowModal(false);
            fetchProducts();
        } catch (error) {
            console.error("Error editing product:", error);
        }
    };

    // Delete product
    const deleteProduct = async (id) => {
        const confirmation = window.confirm("Are you sure you want to delete this product?");
        if (confirmation) {
            try {
                const productRef = doc(db, "products", id);
                await deleteDoc(productRef);
                fetchProducts();
            } catch (error) {
                console.error("Error deleting product:", error);
            }
        }
    };

    // Handle modal open for adding or editing
    const openModal = (product = null) => {
        if (product) {
            setProductData(product); // Pre-fill form with product data for editing
            setEditProductId(product.id);
        } else {
            setProductData({
                category: '',
                details: '',
                imgUrl: [],
                price: '',
                productName: '',
                productType: '',
                returns: '',
                shipping: '',
                sizes: [],
            });
            setEditProductId(null);
        }
        setShowModal(true);
    };

    // Fetch products when component mounts or when products change
    useEffect(() => {
        fetchProducts();
    }, []);

    if (loading) return <p>Loading products...</p>;

    return (
        <div className="manage-products">
            <h1>Manage Products</h1>
            <button onClick={() => openModal()}>Add Product</button>

            {/* Products Table */}
            <table className="products-table">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Category</th>
                        <th>Price</th>
                        <th>Weight</th>
                        <th>Sizes</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product) => (
                        <tr key={product.id}>
                            <td>{product.productName}</td>
                            <td>{product.category}</td>
                            <td>${product.price}</td>
                            <td>{product.weight}</td>
                            <td>{product.sizes.join(', ')}</td>
                            <td>
                                <button onClick={() => openModal(product)}>Update</button>
                                <button onClick={() => deleteProduct(product.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Add/Edit */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>{editProductId ? 'Edit Product' : 'Add Product'}</h2>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            if (editProductId) {
                                editProduct();
                            } else {
                                addProduct();
                            }
                        }}>
                            <div className="form-group">
                                <label>Product Name</label>
                                <input
                                    type="text"
                                    value={productData.productName}
                                    onChange={(e) => setProductData({ ...productData, productName: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Category</label>
                                <select
                                    value={productData.category}
                                    onChange={(e) => setProductData({ ...productData, category: e.target.value })}
                                    required
                                >
                                    <option value="">Select Category</option>
                                    <option value="Physical">Physical</option>
                                    <option value="NFT">NFT</option>
                                    <option value="Grotto">Grotto</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Details</label>
                                <input
                                    type="text"
                                    value={productData.details}
                                    onChange={(e) => setProductData({ ...productData, details: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <label>Price</label>
                                <input
                                    type="number"
                                    value={productData.price}
                                    onChange={(e) => setProductData({ ...productData, price: parseFloat(e.target.value) })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Weight in ounce</label>
                                <input
                                    type="number"
                                    value={productData.weight}
                                    onChange={(e) => setProductData({ ...productData, weight: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Return Policy</label>
                                <textarea
                                    value={productData.returns}
                                    onChange={(e) => setProductData({ ...productData, returns: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <label>Shipping</label>
                                <input
                                    type="text"
                                    value={productData.shipping}
                                    onChange={(e) => setProductData({ ...productData, shipping: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <label>Sizes</label>
                                <div className="checkbox-group">
                                    {['small', 'medium', 'large'].map((size) => (
                                        <label key={size} className="size-check">
                                            <input
                                                type="checkbox"
                                                value={size}
                                                checked={size in productData.sizes}
                                                onChange={handleSizeChange}
                                            />
                                            {size}
                                        </label>
                                    ))}
                                </div>

                                {/* Display quantity inputs for selected sizes */}
                                <div className="size-quantity-group">
                                    {Object.entries(productData.sizes).map(([size, quantity]) => (
                                        <div key={size} className="size-quantity">
                                            <label>{size} quantity:</label>
                                            <input
                                                type="number"
                                                value={quantity}
                                                min="1"
                                                onChange={(event) => handleQuantityChange(size, event)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {Object.keys(productData.sizes || {}).length === 0 && (
                                <div className="form-group">
                                    <label>Quantity:</label>
                                    <input
                                        type="number"
                                        value={productData.quantity || ""}
                                        min="1"
                                        onChange={(event) => setProductData((prev) => ({
                                            ...prev,
                                            quantity: Math.max(1, parseInt(event.target.value, 10) || 1),
                                        }))}
                                    />
                                </div>
                            )}
                            <div className="form-group">
                                <label>Images</label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleImageUpload}
                                />
                                {uploading && <p>Uploading images...</p>}
                            </div>
                            <button type="submit">{editProductId ? 'Update Product' : 'Add Product'}</button>
                            <button onClick={() => setShowModal(false)} className='btn-cancel'>Cancel</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageProducts;
